"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = formatDate;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
function formatDate(date_obj, format) {
  console.log(date_obj);
  var o = {
    'M+': date_obj.getMonth() + 1,
    // month
    'd+': date_obj.getDate(),
    // day
    'H+': date_obj.getHours(),
    // hour
    'm+': date_obj.getMinutes(),
    // minute
    's+': date_obj.getSeconds(),
    // second
    'q+': Math.floor((date_obj.getMonth() + 3) / 3),
    // quarter
    'S': date_obj.getMilliseconds() // millisecond
  };

  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (date_obj.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return format;
}