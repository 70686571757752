"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _admin = require("@/api/admin");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: true,
      heads: [],
      total: 0,
      tableKey: 0,
      list: null,
      form: {
        //  筛选表单
        state: '',
        limit: 10,
        page: 0
      },
      createForm: {
        // 创建表单
        package_name: '',
        package_type_name: ''
      },
      package_options: [],
      //  s筛选条件
      create_package_options: [],
      state_values: {
        0: '驳回',
        1: '待审核',
        2: '通过'
      },
      state_options: [{
        value: 0,
        label: '驳回'
      }, {
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '通过'
      }],
      visible: false,
      // 删除套餐hove弹出框
      outerVisible: false // 新建套餐弹出框
    };
  },
  created: function created() {
    this.onSubmit();
  },
  methods: {
    onSubmit: function onSubmit() {
      this.getList(this.form);
    },
    getList: function getList(form) {
      var _this = this;
      this.listLoading = true;
      (0, _admin.getApplyUpdateCode)(form).then(function (response) {
        console.log(response);
        _this.list = response.results;
        _this.total = response.count;
        _this.heads = response.heads;
        _this.listLoading = false;
      });
    },
    handleApply: function handleApply(row, state) {
      var _this2 = this;
      var txt = '确定';
      var msg = '此操作将添加该配置, 是否继续?';
      if (state === 0) {
        txt = '确认驳回';
        msg = '你正在驳回该条申请, 是否继续?';
      }
      if (state === 2) {
        txt = '确认通过';
        msg = '你正在通过该条申请, 是否继续?';
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: txt,
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var st = row.state;
        row.state = state;
        var data = (0, _objectSpread2.default)({}, row);
        row.state = st;
        (0, _admin.submitApplyUpdateCode)(data).then(function (response) {
          _this2.outerVisible = false;
          row.state = state;
          _this2.$message({
            type: 'success',
            message: '成功!'
          }).catch(function (response) {
            row.state = st;
            _this2.$message({
              type: 'error',
              message: response.msg
            });
          });
          _this2.onSubmit();
        });
      });
    }
  }
};
exports.default = _default;