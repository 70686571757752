var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "calc( 100vh - 60px)", "margin-top": "10px" } },
    [
      _c(
        "el-header",
        [
          _c(
            "el-form",
            {
              attrs: { inline: true },
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名" } },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", clearable: "" },
                        model: {
                          value: _vm.form.member_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "member_name", $$v)
                          },
                          expression: "form.member_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下单时间" } },
                [
                  _c("el-col", [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("span", { staticClass: "demonstration" }),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.form.dateFilte,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "dateFilte", $$v)
                            },
                            expression: "form.dateFilte",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分销商名称" } },
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容", clearable: "" },
                        model: {
                          value: _vm.form.distributor_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "distributor_name", $$v)
                          },
                          expression: "form.distributor_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.settle_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "settle_status", $$v)
                            },
                            expression: "form.settle_status",
                          },
                        },
                        _vm._l(_vm.settle_status_options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href:
                        _vm.ServiceUrl +
                        "/common/shared_order/export/?" +
                        _vm.urlParams(),
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "filter-item",
                        attrs: { type: "primary", icon: "el-icon-download" },
                      },
                      [_vm._v("下载\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingStatistic,
                  expression: "loadingStatistic",
                },
              ],
              attrs: { gutter: 20, type: "flex" },
            },
            _vm._l(_vm.shared_card, function (i, index) {
              return _c("el-col", { key: index, attrs: { span: 5 } }, [
                _c("div", { class: _vm.setClass(index + 1) }, [
                  _c("div", { staticClass: "grid-content-line" }, [
                    _c("a", [
                      _vm._v(
                        "\n              " + _vm._s(i.key) + "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("a", { staticClass: "number-sta" }, [
                      _vm._v(
                        "\n              " + _vm._s(i.value) + "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingList,
                  expression: "loadingList",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "calc(100vh - 400px)",
              },
            },
            _vm._l(_vm.heads, function (iCol) {
              return _c("af-table-column", {
                key: iCol.key,
                attrs: { label: iCol.value, align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          typeof scope.row[iCol.key] === "object" &&
                          scope.row[iCol.key] !== null
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row[iCol.key]["verbose"])),
                              ])
                            : _c("span", [_vm._v(_vm._s(scope.row[iCol.key]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        { staticStyle: { height: "40px" } },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.form.page,
              limit: _vm.form.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.form, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.form, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }