"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/core-js/modules/es6.array.iterator.js");
require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/core-js/modules/es6.promise.js");
require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/core-js/modules/es6.object.assign.js");
require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
var _afTableColumn = _interopRequireDefault(require("af-table-column"));
// A modern alternative to CSS resets

// lang i18n

// global css

// icon
// permission control
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}
_vue.default.use(_vueClipboard.default);
// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});
// 如果想要中文版 element-ui，按如下方式声明
_vue.default.use(_elementUi.default);
_vue.default.use(_afTableColumn.default);
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});