"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.link");
var _merchant = require("@/api/merchant");
var _cookie = require("@/utils/cookie");
var _admin = require("@/api/admin");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      circleUrl: "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1445680350,3299201752&fm=26&gp=0.jpg",
      recordTableVisible: false,
      ruleVisible: false,
      listLoading: true,
      isApplySettle: false,
      isAddPayeeForm: false,
      isApplyCodeForm: false,
      title: "申请结算",
      confirmSettle: false,
      tableKey: 0,
      radio: "2",
      limit_times: 0,
      used_times: 0,
      kol_state: 0,
      code: "",
      settle_records: {
        heads: [],
        list: []
      },
      settle_record: {
        apply_at: "",
        audit_at: "",
        status: "",
        apply_amount: ""
      },
      shared_result: {
        settle_money: "",
        verify_money: "",
        await_money: "",
        verify_members: "",
        verify_pay_members: "",
        verify_orders: ""
      },
      applySettleForm: {
        payee_id: null
      },
      payeeForm: {
        type: "alipay",
        name: "",
        account: ""
      },
      defaultPayee: null,
      applyCodeForm: {
        code: "",
        can_use: true
      },
      payeeTypeOptions: [{
        'value': 'alipay',
        'label': '支付宝'
      }, {
        'value': 'paypal',
        'label': 'Paypal'
      }],
      payeeOptions: [],
      applySettleRules: {
        payee_id: {
          type: "number",
          required: true,
          message: "请选择收款人",
          trigg: "blur"
        }
      },
      applyKolRules: {
        code: {
          type: "string",
          required: true,
          message: "请输入口令",
          trigg: "blur"
        }
      },
      payeeRules: {
        type: {
          type: "string",
          required: true,
          message: "类型不能为空",
          trigg: "blur"
        },
        name: {
          type: "string",
          required: true,
          message: "姓名不能为空",
          trigg: "blur"
        },
        account: {
          type: "string",
          required: true,
          message: "账号不能为空",
          trigg: "blur"
        }
      },
      works_money: "",
      token: (0, _cookie.getCookie)("token"),
      distributor_info: {
        distributor_id: undefined,
        // distributor_password: undefined,
        distributor_name: undefined,
        source_channel: undefined,
        contact_info: undefined,
        summary: undefined
      },
      referral_link_code: {
        distributor_id: undefined,
        referral_code: undefined,
        link: undefined
      },
      applyKolMessage: null
    };
  },
  created: function created() {
    var _this = this;
    this.notice();
    (0, _merchant.getDistributor)(this.token).then(function (response) {
      _this.distributor_info = response.distributor_info;
      _this.referral_link_code = response.referral_detail;
    });
    (0, _merchant.getSettleRecord)(this.token).then(function (response) {
      _this.settle_record = response;
      if (!_this.settle_record.status) {
        _this.settle_record.status = {
          verbose: ""
        };
      }
    });
    (0, _merchant.getSharedResult)(this.token).then(function (response) {
      _this.shared_result = response;
    });
    (0, _merchant.getWorksMoney)(this.token).then(function (response) {
      _this.works_money = response.share_money;
    });
    (0, _merchant.getDistributorCode)(this.token).then(function (response) {
      _this.code = response.code;
      _this.limit_times = response.limit_times;
      _this.used_times = response.used_times;
      _this.kol_state = response.kol_state;
    });
    this.getPayee();
  },
  methods: {
    getPayee: function getPayee() {
      var _this2 = this;
      (0, _merchant.getDistributorPayee)(this.token).then(function (response) {
        _this2.payeeOptions = response.results;
        (0, _merchant.getDistributorDefaultPayee)(_this2.token).then(function (response) {
          console.log(response);
          _this2.applySettleForm.payee_id = response.id;
        });
      });
    },
    notice: function notice() {
      var _this3 = this;
      (0, _admin.Notice)({}).then(function (response) {
        if (response["content"]) {
          _this3.$message({
            message: response["content"],
            showClose: true,
            type: "warning"
          });
        }
      });
    },
    applySettle: function applySettle() {
      if (this.works_money === "" || this.works_money === 0 || this.works_money === null) {
        this.$message({
          type: "warning",
          message: "没有可结算金额"
        });
        // this.isApplySettle = true
      } else this.isApplySettle = true;
    },
    addApplySettleForm: function addApplySettleForm(formName) {
      var _this4 = this;
      var check = true;
      this.$refs[formName].validate(function (valid) {
        if (!valid) {
          check = false;
        }
      });
      if (!check) {
        console.log("error submit!!");
        return false;
      }
      if (!this.confirmSettle) {
        this.confirmSettle = true;
        this.title = "请再次确认你的结算信息";
        return false;
      }
      this.confirmSettle = false;
      this.title = "申请结算";
      (0, _merchant.applySettle)(this.applySettleForm.payee_id).then(function (response) {
        _this4.$message({
          type: "success",
          message: response.msg
        });
        (0, _merchant.getSettleRecord)(_this4.token).then(function (response) {
          _this4.settle_record = response;
          if (!_this4.settle_record.status) {
            _this4.settle_record.status = {
              verbose: ""
            };
          }
        });
        (0, _merchant.getSharedResult)(_this4.token).then(function (response) {
          _this4.shared_result = response;
        });
        (0, _merchant.getWorksMoney)(_this4.token).then(function (response) {
          _this4.works_money = response.share_money;
        });
      });
      (0, _merchant.getSettleRecord)(this.token).then(function (response) {
        _this4.settle_record = response;
        if (!_this4.settle_record.status) {
          _this4.settle_record.status = {
            verbose: ""
          };
        }
      });
      (0, _merchant.getSharedResult)(this.token).then(function (response) {
        _this4.shared_result = response;
      });
      (0, _merchant.getWorksMoney)(this.token).then(function (response) {
        _this4.works_money = response.share_money;
      });
      this.isApplySettle = false;
    },
    getSettleRecordButton: function getSettleRecordButton() {
      var _this5 = this;
      (0, _merchant.getSettleRecords)().then(function (response) {
        _this5.listLoading = false;
        _this5.settle_records.heads = response.heads;
        _this5.settle_records.list = response.results;
      });
      this.recordTableVisible = true;
    },
    onCopy: function onCopy(e) {
      this.$message.success("内容已复制到剪切板！");
    },
    onCopy1: function onCopy1(e) {
      if (this.referral_link_code.link.length <= 28) {
        this.$message.error("抱歉，复制失败！");
        return;
      }
      this.$message.success("内容已复制到剪切板！");
    },
    // 复制失败时的回调函数
    onError: function onError(e) {
      this.$message.error("抱歉，复制失败！");
    },
    checkCode: function checkCode(v) {
      var _this6 = this;
      this.applyCodeForm.code = event.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "");
      if (v.length < 1) {
        this.applyCodeForm.can_use = false;
        return;
      }
      (0, _merchant.getCheckCode)({
        code: v
      }).then(function (response) {
        _this6.applyCodeForm.can_use = response.status;
      });
    },
    applyKol: function applyKol() {
      this.applyKolMessage && this.applyKolMessage.close();
      if (this.kol_state === 1) {
        this.applyKolMessage = this.$message({
          type: "warning",
          message: "已有申请中的专属口令，请耐心等待审核"
        });
        return;
      }
      // if (this.used_times < this.limit_times) {
      if (this.used_times < 1) {
        this.applyKolMessage = this.$message({
          type: "warning",
          message: "成功推广40个人后才可申请专属推广口令"
          // message: "成功推广" + this.limit_times + "个人后才可申请专属推广口令"
        });

        return;
      }
      this.isApplyCodeForm = true;
    },
    applyKolCode: function applyKolCode(formName) {
      var _this7 = this;
      if (!this.applyCodeForm.can_use) {
        this.applyKolMessage = this.$message({
          type: "warning",
          message: "当前口令已被使用，请更换"
        });
        return;
      }
      var check = true;
      this.$refs[formName].validate(function (valid) {
        if (!valid) {
          check = false;
        }
      });
      if (!check) {
        console.log("error submit!!");
        return false;
      }
      (0, _merchant.getApplyKol)({
        code: this.applyCodeForm.code
      }).then(function (response) {
        if (response.state) {
          _this7.$message({
            type: "success",
            message: "申请成功!"
          });
          _this7.getPayee();
        } else {
          _this7.$message({
            type: "warning",
            message: response.msg
          });
        }
        _this7.applyCodeForm.can_use = true;
        _this7.isApplyCodeForm = false;
      }).catch(function () {
        _this7.$message({
          type: "error",
          message: "申请失败，请重试"
        });
      });
    },
    addPayee: function addPayee(formName) {
      var _this8 = this;
      var check = true;
      this.$refs[formName].validate(function (valid) {
        if (!valid) {
          check = false;
        }
      });
      if (!check) {
        console.log("error submit!!");
        return false;
      }
      // this.payeeForm.alipay_name = this.payeeForm.alipay_name.trim();
      // this.payeeForm.alipay_account = this.payeeForm.alipay_account.trim();
      (0, _merchant.addDistributorPayee)(this.payeeForm).then(function (response) {
        _this8.$message({
          type: "success",
          message: "添加成功!"
        });
        _this8.getPayee();
        _this8.isAddPayeeForm = false;
      }).catch(function () {
        _this8.$message({
          type: "error",
          message: "添加失败，请重试"
        });
      });
    },
    changeRadio: function changeRadio(value) {
      this.radio = value;
    }
  },
  watch: {
    isApplyCodeForm: function isApplyCodeForm(newValue, oldValue) {
      var _this9 = this;
      if (newValue) {
        this.$nextTick(function () {
          var exclusiveCommandRef = document.getElementById("exclusiveCommandRef");
          exclusiveCommandRef.addEventListener("compositionstart", function () {
            _this9.applyCodeForm.code = event.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "");
          });
          exclusiveCommandRef.addEventListener("compositionend", function () {
            _this9.applyCodeForm.code = event.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "");
          });
        });
      }
    }
  }
};
exports.default = _default;