"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'roles']))
};
exports.default = _default;