var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "calc( 100vh - 60px)", "margin-top": "10px" } },
    [
      _c("el-header", { staticStyle: { height: "20px" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: { model: _vm.form, "label-width": "5%" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "分销商名称", "label-width": "100" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "15%" },
                          model: {
                            value: _vm.form.distributor_username,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "distributor_username", $$v)
                            },
                            expression: "form.distributor_username",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.form.dateFilte,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "dateFilte", $$v)
                            },
                            expression: "form.dateFilte",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onSubmit },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onCreate },
                          },
                          [_vm._v("新增")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                _vm.ServiceUrl +
                                "/common/v2/distributor_profit/export/?" +
                                _vm.urlParams(),
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" },
                                ],
                                staticClass: "filter-item",
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-download",
                                },
                              },
                              [_vm._v("下载\n                ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "calc(100vh - 200px)",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              { attrs: { span: 24 } },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "年卡金额: " +
                                        _vm._s(
                                          props.row["year_package_money"] || 0
                                        ) +
                                        " 元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "year_package_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "季卡金额: " +
                                        _vm._s(
                                          props.row["quarter_package_money"] ||
                                            0
                                        ) +
                                        "  元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "quarter_package_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "月卡金额: " +
                                        _vm._s(
                                          props.row["month_package_money"] || 0
                                        ) +
                                        "  元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "month_package_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "计时金额: " +
                                        _vm._s(
                                          props.row["timing_package_money"] || 0
                                        ) +
                                        " 元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "timing_package_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { span: 24 },
                              },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "专享金额: " +
                                        _vm._s(
                                          props.row["single_package_money"] || 0
                                        ) +
                                        " 元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "single_package_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "首充金额: " +
                                        _vm._s(
                                          props.row["first_charge_money"] || 0
                                        ) +
                                        "  元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "first_charge_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "销售金额: " +
                                        _vm._s(
                                          props.row["total_charge_money"] || 0
                                        ) +
                                        "  元,分成: " +
                                        _vm._s(
                                          props.row[
                                            "total_charge_share_money"
                                          ] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("span", [
                                    _vm._v(
                                      "注册分成: " +
                                        _vm._s(
                                          props.row["register_money"] || 0
                                        ) +
                                        "  元"
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm._l(_vm.heads, function (iCol) {
                return _c("af-table-column", {
                  key: iCol.key,
                  attrs: {
                    label: iCol.value,
                    "min-width": "30",
                    "max-width": "31",
                    align: "center",
                    fit: "true",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            iCol.key === "operation"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showDetail(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("查看")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editDetail(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            typeof scope.row[iCol.key] === "object" &&
                            scope.row[iCol.key] !== null &&
                            iCol.key !== "operation"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row[iCol.key]["verbose"])
                                  ),
                                ])
                              : iCol.key === "register_members"
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row[iCol.key] || 0)),
                                ])
                              : iCol.key === "refund_orders"
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row[iCol.key] || 0)),
                                ])
                              : iCol.key === "shared_money"
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row[iCol.key] || 0)),
                                ])
                              : iCol.key === "orders"
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row[iCol.key] || 0)),
                                ])
                              : iCol.key === "share_type"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.share_values[scope.row[iCol.key]] ||
                                        "-"
                                    )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[iCol.key])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        { staticStyle: { height: "40px" } },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              background: false,
              total: _vm.total,
              page: _vm.form.page,
              limit: _vm.form.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.form, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.form, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分销商信息",
            "close-on-click-modal": false,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.resetFields,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "分销商名称",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              readonly:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.distributor_info.distributor_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.distributor_info,
                                  "distributor_name",
                                  $$v
                                )
                              },
                              expression: "distributor_info.distributor_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "来源渠道",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              readonly:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.distributor_info.source_channel,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.distributor_info,
                                  "source_channel",
                                  $$v
                                )
                              },
                              expression: "distributor_info.source_channel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "联系方式",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              readonly:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.distributor_info.contact_info,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.distributor_info,
                                  "contact_info",
                                  $$v
                                )
                              },
                              expression: "distributor_info.contact_info",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "推荐码",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "大小写字母与数字组合",
                              maxlength: "20",
                              readonly:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.referral_link_code.referral_code,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.referral_link_code,
                                  "referral_code",
                                  $$v
                                )
                              },
                              expression: "referral_link_code.referral_code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "有效注册判定",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: "1",
                              max: "99999",
                              disabled:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.distributor_info.new_day,
                              callback: function ($$v) {
                                _vm.$set(_vm.distributor_info, "new_day", $$v)
                              },
                              expression: "distributor_info.new_day",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("天")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "兑换码渠道商",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                disabled:
                                  _vm.DialogAction === "show" ? true : false,
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.referral_link_code.cdk_channel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.referral_link_code,
                                    "cdk_channel",
                                    $$v
                                  )
                                },
                                expression: "referral_link_code.cdk_channel",
                              },
                            },
                            _vm._l(_vm.cdk_channel_options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "渠道号",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "仅仅支持数字",
                              min: "0",
                              readonly:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.referral_link_code.channel,
                              callback: function ($$v) {
                                _vm.$set(_vm.referral_link_code, "channel", $$v)
                              },
                              expression: "referral_link_code.channel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "is-required",
                          attrs: {
                            label: "网址",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                maxlength: "40",
                                placeholder: "大小写字母与数字组合",
                                readonly:
                                  _vm.DialogAction === "show" ? true : false,
                                autocomplete: "off",
                              },
                              model: {
                                value: _vm.referral_link_code.link,
                                callback: function ($$v) {
                                  _vm.$set(_vm.referral_link_code, "link", $$v)
                                },
                                expression: "referral_link_code.link",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v(_vm._s(_vm.registerPrefix)),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "概况",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "100",
                              readonly:
                                _vm.DialogAction === "show" ? true : false,
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.distributor_info.summary,
                              callback: function ($$v) {
                                _vm.$set(_vm.distributor_info, "summary", $$v)
                              },
                              expression: "distributor_info.summary",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "is-required",
                              attrs: {
                                label: "CPA分成",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  disabled:
                                    _vm.DialogAction === "show" ? true : false,
                                  "inline-prompt": "",
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "是",
                                  "inactive-text": "否",
                                },
                                model: {
                                  value: _vm.distributor_info.open_cpa,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.distributor_info,
                                      "open_cpa",
                                      $$v
                                    )
                                  },
                                  expression: "distributor_info.open_cpa",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "单价",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  disabled:
                                    _vm.DialogAction === "show" ? true : false,
                                  min: "0",
                                  max: "99999",
                                  autocomplete: "off",
                                },
                                model: {
                                  value: _vm.distributor_info.cpa_price,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.distributor_info,
                                      "cpa_price",
                                      $$v
                                    )
                                  },
                                  expression: "distributor_info.cpa_price",
                                },
                              }),
                              _c("span", [_vm._v("元")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "is-required",
                              attrs: {
                                label: "分成类型",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.DialogAction === "show"
                                        ? true
                                        : false,
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.distributor_info.share_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.distributor_info,
                                        "share_type",
                                        $$v
                                      )
                                    },
                                    expression: "distributor_info.share_type",
                                  },
                                },
                                _vm._l(_vm.share_options, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.distributor_share_rule, function (i, index) {
                    return _c(
                      "el-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.distributor_info.share_type ===
                              "PACKAGE_SALE",
                            expression:
                              "distributor_info.share_type === 'PACKAGE_SALE'",
                          },
                        ],
                        key: index,
                        attrs: { span: 8 },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "margin-bottom": "20px",
                                  color: "red",
                                },
                                attrs: { span: 24 },
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.package_type_options[i.package_type]
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "首单(%)",
                                  "label-width": _vm.formLabelWidth,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    readonly:
                                      _vm.DialogAction === "show"
                                        ? true
                                        : false,
                                    autocomplete: "off",
                                  },
                                  model: {
                                    value: i.first_charge,
                                    callback: function ($$v) {
                                      _vm.$set(i, "first_charge", $$v)
                                    },
                                    expression: "i.first_charge",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "首续(%)",
                                  "label-width": _vm.formLabelWidth,
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    readonly:
                                      _vm.DialogAction === "show"
                                        ? true
                                        : false,
                                    autocomplete: "off",
                                  },
                                  model: {
                                    value: i.first_renew,
                                    callback: function ($$v) {
                                      _vm.$set(i, "first_renew", $$v)
                                    },
                                    expression: "i.first_renew",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(
                    _vm.distributor_share_total_charge_rule,
                    function (i, index) {
                      return _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.distributor_info.share_type ===
                                "TOTAL_CHARGE",
                              expression:
                                "distributor_info.share_type === 'TOTAL_CHARGE'",
                            },
                          ],
                          key: index,
                          attrs: { span: 24 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "销售额达到",
                                    "label-width": _vm.formLabelWidth,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: "0",
                                      max: index === 0 ? 0 : 9999999,
                                      disabled:
                                        index === 0 ||
                                        _vm.DialogAction === "show"
                                          ? true
                                          : false,
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: i.reach,
                                      callback: function ($$v) {
                                        _vm.$set(i, "reach", $$v)
                                      },
                                      expression: "i.reach",
                                    },
                                  }),
                                  _c("span", [_vm._v("元")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "比例变为",
                                    "label-width": _vm.formLabelWidth,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: "0",
                                      max: "100",
                                      autocomplete: "off",
                                      disabled:
                                        _vm.DialogAction === "show"
                                          ? true
                                          : false,
                                    },
                                    model: {
                                      value: i.ratio,
                                      callback: function ($$v) {
                                        _vm.$set(i, "ratio", $$v)
                                      },
                                      expression: "i.ratio",
                                    },
                                  }),
                                  _c("span", [_vm._v("%")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.distributor_share_first_charge_rule,
                    function (i, index) {
                      return _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.distributor_info.share_type ===
                                "FIRST_CHARGE",
                              expression:
                                "distributor_info.share_type === 'FIRST_CHARGE'",
                            },
                          ],
                          key: index,
                          attrs: { span: 24 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "销售额达到",
                                    "label-width": _vm.formLabelWidth,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: "0",
                                      max: index === 0 ? 0 : 9999999,
                                      disabled:
                                        index === 0 ||
                                        _vm.DialogAction === "show"
                                          ? true
                                          : false,
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: i.reach,
                                      callback: function ($$v) {
                                        _vm.$set(i, "reach", $$v)
                                      },
                                      expression: "i.reach",
                                    },
                                  }),
                                  _c("span", [_vm._v("元")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "比例变为",
                                    "label-width": _vm.formLabelWidth,
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: "0",
                                      max: "100",
                                      autocomplete: "off",
                                      disabled:
                                        _vm.DialogAction === "show"
                                          ? true
                                          : false,
                                    },
                                    model: {
                                      value: i.ratio,
                                      callback: function ($$v) {
                                        _vm.$set(i, "ratio", $$v)
                                      },
                                      expression: "i.ratio",
                                    },
                                  }),
                                  _c("span", [_vm._v("%")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "密码",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.distributor_info.distributor_password)
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.formCancel } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.formSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }