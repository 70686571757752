var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "welcome-bg" }, [
      _c("img", {
        attrs: { src: require("../../assets/home/welcome-bg.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "welcome-tip" }, [
        _c("p", [
          _vm._v("欢迎"),
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "strong" }, [_vm._v("回归")]),
        _vm._v(" "),
        _c("p", [_vm._v("六毫秒大家庭")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }