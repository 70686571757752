import request from '@/utils/request';
export function getDistributorList(params) {
  return request({
    url: 'common/distributor_profit/',
    method: 'get',
    params: params
  });
}
export function getDistributor(params) {
  return request({
    url: 'common/distributor/' + params + '/',
    method: 'get',
    params: params
  });
}
export function addDistributor(params) {
  return request({
    url: 'common/distributor/',
    method: 'post',
    data: params
  });
}
export function editDistributor(distributor_id, params) {
  return request({
    url: 'common/distributor/' + distributor_id + '/',
    method: 'patch',
    data: params
  });
}
export function getPackageConfig(params) {
  // 获取分销的套餐
  return request({
    url: 'common/shared_package/',
    method: 'get',
    params: params
  });
}
export function getAllPackage() {
  // 获取所有的套餐，用来做添加时候的下拉筛选
  return request({
    url: 'common/shared_package/get_all_packages/',
    method: 'get'
  });
}
export function getPackage() {
  // 用来做下拉筛选
  return request({
    url: 'common/shared_package/get_packages/',
    method: 'get'
  });
}
export function delPackage(params) {
  // 删除分销的套餐
  return request({
    url: 'common/shared_package/del_package/',
    method: 'get',
    params: params
  });
}
export function addPackage(params) {
  // 添加被分销的套餐
  return request({
    url: 'common/shared_package/add_package/',
    method: 'get',
    params: params
  });
}
export function getShareOrder(params) {
  // 分销订单
  return request({
    url: 'common/shared_order/',
    method: 'get',
    params: params
  });
}
export function distributorSettle(params) {
  // 分销商结算列表
  return request({
    url: 'common/distributor_settle/',
    method: 'get',
    params: params
  });
}
export function getSharedCard(params) {
  return request({
    url: 'common/shared_order/shared_flow_stat/',
    method: 'get',
    params: params
  });
}
export function distributorSettleStatistic(params) {
  // 分销商结算列表统计
  return request({
    url: 'common/distributor_settle/statistical/',
    method: 'get',
    params: params
  });
}
export function distributorSettleUpdate(distributor_id, params) {
  // 分销商结算更新
  return request({
    url: 'common/distributor_settle/' + distributor_id + '/',
    method: 'patch',
    data: params
  });
}
export function Notice(params) {
  // 分销商结算列表统计
  return request({
    url: 'common/notices/new/',
    method: 'get',
    params: params
  });
}
export function getCdkChannel(params) {
  // 兑换码渠道商列表
  return request({
    url: 'common/cdk_channel/cdk_channel/',
    method: 'get',
    params: params
  });
}
export function getApplyUpdateCode(params) {
  // 专属口令
  return request({
    url: 'common/distributor_update_code/',
    method: 'get',
    params: params
  });
}
export function submitApplyUpdateCode(data) {
  // 专属口令审核
  return request({
    url: 'common/distributor_update_code/audit/',
    method: 'post',
    data: data
  });
}