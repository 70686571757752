"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDistributorPayee = addDistributorPayee;
exports.applySettle = applySettle;
exports.getApplyKol = getApplyKol;
exports.getCheckCode = getCheckCode;
exports.getDistributor = getDistributor;
exports.getDistributorCode = getDistributorCode;
exports.getDistributorDefaultPayee = getDistributorDefaultPayee;
exports.getDistributorPayee = getDistributorPayee;
exports.getMemberCount = getMemberCount;
exports.getMemberRegister = getMemberRegister;
exports.getSettleRecord = getSettleRecord;
exports.getSettleRecords = getSettleRecords;
exports.getShareOrder = getShareOrder;
exports.getShareOrderMoneySum = getShareOrderMoneySum;
exports.getSharedResult = getSharedResult;
exports.getWorksMoney = getWorksMoney;
var _request = _interopRequireDefault(require("@/utils/request"));
function getMemberRegister(params) {
  return (0, _request.default)({
    url: 'merchant/member_register/',
    method: 'get',
    params: params
  });
}
function getMemberCount(params) {
  return (0, _request.default)({
    url: 'merchant/member_register/member_count/',
    method: 'get',
    params: params
  });
}
function getShareOrder(params) {
  return (0, _request.default)({
    url: 'merchant/shared_order/',
    method: 'get',
    params: params
  });
}
function getShareOrderMoneySum(params) {
  return (0, _request.default)({
    url: 'merchant/shared_order/money_sum/',
    method: 'get',
    params: params
  });
}
function getSettleRecord(params) {
  return (0, _request.default)({
    url: 'merchant/settle_record/' + 'settle_record_last' + '/',
    method: 'get'
  });
}
function getSettleRecords() {
  return (0, _request.default)({
    url: 'merchant/settle_record/',
    method: 'get'
  });
}
function getSharedResult(params) {
  return (0, _request.default)({
    url: 'merchant/shared_result/' + 'shared_result' + '/',
    method: 'get'
  });
}
function getWorksMoney(params) {
  return (0, _request.default)({
    url: 'merchant/works_money/' + 'get_work_mony' + '/',
    method: 'get'
  });
}
function applySettle(payee_id) {
  return (0, _request.default)({
    url: 'merchant/apply_settle/?payee_id=' + payee_id,
    method: 'get'
  });
}
function getDistributor(params) {
  return (0, _request.default)({
    url: 'merchant/distributor/' + 'get_dis' + '/',
    method: 'get'
  });
}
function getDistributorCode() {
  return (0, _request.default)({
    url: 'common/distributor_code/get_code/',
    method: 'get'
  });
}
function getCheckCode(data) {
  return (0, _request.default)({
    url: 'common/distributor_code/check_code/',
    method: 'post',
    data: data
  });
}
function getApplyKol(data) {
  return (0, _request.default)({
    url: 'common/distributor_code/apply_kol/',
    method: 'post',
    data: data
  });
}
function getDistributorPayee(params) {
  return (0, _request.default)({
    url: 'merchant/payee/',
    method: 'get'
  });
}
function addDistributorPayee(data) {
  return (0, _request.default)({
    url: 'merchant/payee/',
    method: 'post',
    data: data
  });
}
function getDistributorDefaultPayee(params) {
  return (0, _request.default)({
    url: 'merchant/payee/get_default',
    method: 'get'
  });
}