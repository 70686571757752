"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _admin = require("@/api/admin");
var _date = _interopRequireDefault(require("@/utils/date"));
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _cookie = require("@/utils/cookie");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
var _default = {
  name: 'DistributorSettleList',
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      ServiceUrl: process.env.VUE_APP_BASE_API,
      distributor_name: '',
      listLoading: true,
      token: (0, _cookie.getCookie)('token'),
      statisticLoading: true,
      statisticData: {
        apply_amount_sum: 0,
        actual_amount_sum: 9,
        verify_amount_sum: 9,
        await_amount_sum: 9
        // works_amount_sum: 9
      },

      statusOptions: [{
        value: 'SETTLE',
        label: '已结算'
      }, {
        value: 'AUDITING',
        label: '审批中'
      }],
      status: '',
      // apply_at: [new Date(), new Date()],
      apply_at: null,
      heads: [],
      list: [],
      total: 0,
      currentPage: 1,
      currentPageSize: 10
    };
  },
  created: function created() {
    this.handleFilter();
  },
  methods: {
    urlParams: function urlParams() {
      var result = [];
      if (this.token) {
        result.push('token=' + this.token);
      }
      if (this.status) {
        result.push('status=' + this.status);
      }
      if (this.distributor_name) {
        result.push('distributor_name=' + this.distributor_name);
      }
      if (this.apply_at) {
        result.push('apply_at__gte=' + (0, _date.default)(this.apply_at[0], 'yyyy-MM-dd 00:00:00'));
        result.push('apply_at__lte=' + (0, _date.default)(this.apply_at[1], 'yyyy-MM-dd 23:59:59'));
      }
      if (result) {
        return result.join('&');
      } else {
        return '';
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.currentPageSize = val;
      this.handleFilter();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.handleFilter();
    },
    handleFilter: function handleFilter() {
      var _this = this;
      this.listLoading = true;
      this.statisticLoading = true;
      var queryData = {
        page: this.currentPage,
        limit: this.currentPageSize,
        apply_at__gte: null,
        apply_at__lte: null,
        status: this.status,
        distributor_name: this.distributor_name
      };
      if (this.apply_at) {
        queryData.apply_at__gte = (0, _date.default)(this.apply_at[0], 'yyyy-MM-dd 00:00:00');
        queryData.apply_at__lte = (0, _date.default)(this.apply_at[1], 'yyyy-MM-dd 23:23:59');
      }
      (0, _admin.distributorSettle)(queryData).then(function (response) {
        _this.list = response.results;
        _this.total = response.count;
        _this.heads = response.heads;
        _this.listLoading = false;
      });
      (0, _admin.distributorSettleStatistic)(queryData).then(function (response) {
        _this.statisticData = response;
        _this.statisticLoading = false;
      });
    },
    settle: function settle(record_id, settle_amount) {
      var _this2 = this;
      this.$confirm('提交后不可撤回和修改，确定提交吗？', '结算 ' + settle_amount + ' 元', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var currentDate = (0, _date.default)(new Date(), 'yyyy-MM-dd HH:mm:ss');
        console.log(currentDate);
        (0, _admin.distributorSettleUpdate)(record_id, {
          status: 'SETTLE',
          audit_at: currentDate,
          actual_amount: settle_amount
        }).then(function (response) {
          _this2.$message({
            type: 'success',
            message: '结算成功!'
          });
          _this2.handleFilter();
        }).catch(function (response) {
          _this2.$message({
            type: 'error',
            message: '结算失败'
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消结算'
        });
      });
    }
  }
};
exports.default = _default;