var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "calc( 100vh - 60px)", "margin-top": "10px" } },
    [
      _c(
        "el-header",
        { staticStyle: { height: "20px" } },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分销商名称", "label-width": "200" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.distributor_username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "distributor_username", $$v)
                      },
                      expression: "form.distributor_username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.form.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    _vm._l(_vm.state_options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.outerVisible = true
                        },
                      },
                    },
                    [_vm._v("新建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "calc(100vh - 200px)",
              },
            },
            _vm._l(_vm.heads, function (iCol) {
              return _c("af-table-column", {
                key: iCol.key,
                attrs: {
                  label: iCol.value,
                  "min-width": "30",
                  "max-width": "31",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          iCol.key === "operation"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        disabled: scope.row["state"] !== 1,
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleApply(scope.row, 2)
                                        },
                                      },
                                    },
                                    [_vm._v("通过")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        disabled: scope.row["state"] !== 1,
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleApply(scope.row, 0)
                                        },
                                      },
                                    },
                                    [_vm._v("驳回")]
                                  ),
                                ],
                                1
                              )
                            : iCol.key === "state"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.state_values[scope.row[iCol.key]])
                                ),
                              ])
                            : _c("span", [_vm._v(_vm._s(scope.row[iCol.key]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        { staticStyle: { height: "40px" } },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total >= 0,
                expression: "total>=0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.form.page,
              limit: _vm.form.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.form, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.form, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }