"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delCookie = delCookie;
exports.getCookie = getCookie;
exports.setCookie = setCookie;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.constructor");
// 获取cookie、
function getCookie(name) {
  var arr;
  var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  // eslint-disable-next-line no-cond-assign
  if (arr = document.cookie.match(reg)) {
    return arr[2];
  } else {
    return null;
  }
}

// 设置cookie,增加到vue实例方便全局调用
function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + '=' + escape(value) + (expiredays == null ? '' : ';expires=' + exdate.toGMTString());
}

// 删除cookie
function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null) {
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
  }
}