"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _date = _interopRequireDefault(require("@/utils/date"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _merchant = require("@/api/merchant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      loadingStatistic: true,
      loadingList: true,
      settle_status_options: [
      // 筛选项下拉框
      {
        value: 'AWAIT',
        label: '待结算'
      }, {
        value: 'LOSE',
        label: '已失效'
      }, {
        value: 'SETTLE',
        label: '已结算'
      }, {
        value: 'VERIFY',
        label: '在途中 '
      }, {
        value: 'PROGRESS',
        label: '结算中'
      }],
      form: {
        // 筛选条件
        dateFilte: ['', ''],
        pay_time__gte: '',
        pay_time__lte: '',
        member_name: '',
        settle_status: '',
        limit: 10,
        page: 0
      },
      heads: [],
      total: 0,
      tableKey: 0,
      card_shared_money: {
        shared_money: 0,
        verify_money: 0
      },
      // 卡片数字 有效注册人户和有效购买人数
      list: null // 表格内容
    };
  },

  watch: {
    form: {
      handler: function handler(newName, oldName) {
        if (this.form.dateFilte && this.form.dateFilte[0] !== '') {
          this.form.pay_time__gte = (0, _date.default)(this.form.dateFilte[0], 'yyyy-MM-dd 00:00:00');
          this.form.pay_time__lte = (0, _date.default)(this.form.dateFilte[1], 'yyyy-MM-dd 23:59:59');
        } else {
          this.form.pay_time__gte = '';
          this.form.pay_time__lte = '';
        }
      },
      deep: true
    }
  },
  created: function created() {
    this.onSubmit();
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;
      // 筛选框按钮
      this.loadingStatistic = true;
      (0, _merchant.getShareOrderMoneySum)(this.form).then(function (response) {
        // 获取卡片内容
        _this.card_shared_money = response;
        _this.loadingStatistic = false;
      });
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.loadingList = true;
      (0, _merchant.getShareOrder)(this.form).then(function (response) {
        _this2.list = response.results;
        _this2.total = response.count;
        _this2.heads = response.heads;
        _this2.loadingList = false;
      });
    }
  }
};
exports.default = _default;