var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "calc( 100vh - 60px)", "margin-top": "10px" } },
    [
      _c(
        "el-header",
        { staticStyle: { height: "20px" } },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.package_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "package_id", $$v)
                        },
                        expression: "form.package_id",
                      },
                    },
                    _vm._l(_vm.package_options, function (item) {
                      return _c("el-option", {
                        key: item.package_id,
                        attrs: {
                          label: item.package_name,
                          value: item.package_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.outerVisible = true
                        },
                      },
                    },
                    [_vm._v("新建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              key: _vm.tableKey,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "calc(100vh - 200px)",
              },
            },
            [
              _vm._l(_vm.heads, function (iCol) {
                return _c("af-table-column", {
                  key: iCol.key,
                  attrs: {
                    label: iCol.value,
                    "min-width": "30",
                    "max-width": "31",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            typeof scope.row[iCol.key] === "object" &&
                            scope.row[iCol.key] !== null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(scope.row[iCol.key]["verbose"])
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[iCol.key])),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletePackage(scope)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-footer",
        { staticStyle: { height: "40px" } },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total >= 0,
                expression: "total>=0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.form.page,
              limit: _vm.form.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.form, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.form, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "选择新建套餐", visible: _vm.outerVisible },
          on: {
            "update:visible": function ($event) {
              _vm.outerVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "套餐名称(套餐渠道/套餐名称)" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.createForm.package_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.createForm, "package_name", $$v)
                        },
                        expression: "createForm.package_name",
                      },
                    },
                    _vm._l(_vm.create_package_options, function (item) {
                      return _c("el-option", {
                        key: item.package_id,
                        attrs: {
                          label: item.package_name,
                          value: item.package_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "分销套餐组" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.createForm.package_type_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.createForm, "package_type_name", $$v)
                        },
                        expression: "createForm.package_type_name",
                      },
                    },
                    _vm._l(_vm.package_type_options, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.outerVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.addPackageButton } }, [
                _vm._v("添加"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }