"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _admin = require("@/api/admin");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      listLoading: true,
      heads: [],
      total: 0,
      tableKey: 0,
      list: null,
      form: {
        //  筛选表单
        package_id: '',
        limit: 10,
        page: 0
      },
      createForm: {
        // 创建表单
        package_name: '',
        package_type_name: ''
      },
      package_options: [],
      //  s筛选条件
      create_package_options: [],
      package_type_options: [{
        //  新建套餐筛选条件
        value: 'WEEKS_PACKAGE',
        label: '周卡'
      }, {
        //  新建套餐筛选条件
        value: 'MONTH_PACKAGE',
        label: '月卡'
      }, {
        value: 'QUARTER_PACKAGE',
        label: '季卡'
      }, {
        value: 'HALF_YEAR_PACKAGE',
        label: '半年'
      }, {
        value: 'YEAR_PACKAGE',
        label: '年卡'
      }, {
        value: 'MONTH_SUBSCRIBE_PACKAGE',
        label: '连续包月'
      }, {
        value: 'QUARTER_SUBSCRIBE_PACKAGE',
        label: '连续包季'
      }, {
        value: 'YEAR_SUBSCRIBE_PACKAGE',
        label: '连续包年'
      }],
      visible: false,
      // 删除套餐hove弹出框
      outerVisible: false // 新建套餐弹出框
    };
  },
  created: function created() {
    var _this = this;
    (0, _admin.getPackage)().then(function (response) {
      _this.package_options = response;
    });
    (0, _admin.getAllPackage)().then(function (response) {
      _this.create_package_options = response;
    });
    this.onSubmit();
  },
  methods: {
    deletePackage: function deletePackage(scope) {
      var _this2 = this;
      this.$confirm('确定删除' + '\'' + scope.row.biz_line + '/' + scope.row.package_name + '\'' + '套餐么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _admin.delPackage)({
          'shared_package_id': scope.row.id
        }).then(function (response) {
          _this2.visible = false;
          _this2.onSubmit();
          _this2.$message({
            type: 'success',
            message: '成功!'
          });
        });
      });
    },
    onSubmit: function onSubmit() {
      this.getList(this.form);
    },
    getList: function getList(form) {
      var _this3 = this;
      this.listLoading = true;
      (0, _admin.getPackageConfig)(form).then(function (response) {
        console.log(response);
        _this3.list = response.results;
        _this3.total = response.count;
        _this3.heads = response.heads;
        _this3.listLoading = false;
      });
    },
    addPackageButton: function addPackageButton() {
      var _this4 = this;
      this.$confirm('此操作将添加该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _admin.addPackage)(_this4.createForm).then(function (response) {
          _this4.outerVisible = false;
          _this4.$message({
            type: 'success',
            message: '成功!'
          });
          _this4.onSubmit();
        });
      });
    }
  }
};
exports.default = _default;