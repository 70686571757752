"use strict";

var _interopRequireDefault = require("/Users/bintu/PycharmProjects/qyoversea/DMSFrontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _date = _interopRequireDefault(require("@/utils/date"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _admin = require("@/api/admin");
var _cookie = require("@/utils/cookie");
var _waves = _interopRequireDefault(require("@/directive/waves"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      loadingList: true,
      loadingStatistic: true,
      token: (0, _cookie.getCookie)('token'),
      ServiceUrl: process.env.VUE_APP_BASE_API,
      settle_status_options: [{
        value: 'AWAIT',
        label: '待结算'
      }, {
        value: 'LOSE',
        label: '已失效'
      }, {
        value: 'SETTLE',
        label: '已结算'
      }, {
        value: 'VERIFY',
        label: '在途中 '
      }, {
        value: 'PROGRESS',
        label: '结算中'
      }],
      form: {
        settle_status: '',
        member_name: '',
        distributor_name: '',
        dateFilte: ['', ''],
        pay_time__gte: '',
        pay_time__lte: '',
        limit: 20,
        page: 1
      },
      shared_card: [],
      heads: [],
      total: 0,
      tableKey: 0,
      list: null
    };
  },
  watch: {
    form: {
      handler: function handler(newName, oldName) {
        if (this.form.dateFilte && this.form.dateFilte[0] !== '') {
          this.form.pay_time__gte = (0, _date.default)(this.form.dateFilte[0], 'yyyy-MM-dd 00:00:00');
          this.form.pay_time__lte = (0, _date.default)(this.form.dateFilte[1], 'yyyy-MM-dd 23:59:59');
        } else {
          this.form.pay_time__gte = '';
          this.form.pay_time__lte = '';
        }
      },
      deep: true
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    setClass: function setClass(item) {
      var type = {
        1: 'grid-content bg-purple-1',
        2: 'grid-content bg-purple-2',
        3: 'grid-content bg-purple-3',
        4: 'grid-content bg-purple-4',
        5: 'grid-content bg-purple-5',
        6: 'grid-content bg-purple-6',
        7: 'grid-content bg-purple-7'
      };
      var obj = {};
      obj[type[item]] = true;
      return obj;
    },
    urlParams: function urlParams() {
      var result = [];
      if (this.token) {
        result.push('token=' + this.token);
      }
      if (this.form.settle_status) {
        result.push('settle_status=' + this.form.settle_status);
      }
      if (this.form.distributor_name) {
        result.push('distributor_name=' + this.form.distributor_name);
      }
      if (this.form.dateFilte) {
        result.push('pay_time__gte=' + this.form.pay_time__gte);
        result.push('pay_time__lte=' + this.form.pay_time__lte);
      }
      if (result) {
        return result.join('&');
      } else {
        return '';
      }
    },
    onSubmit: function onSubmit() {
      this.getList();
    },
    onDownload: function onDownload() {},
    getList: function getList() {
      var _this = this;
      this.loadingList = true;
      this.loadingStatistic = true;
      (0, _admin.getSharedCard)(this.form).then(function (response) {
        _this.shared_card = response;
        _this.loadingStatistic = false;
      });
      (0, _admin.getShareOrder)(this.form).then(function (response) {
        _this.list = response.results;
        _this.heads = response.heads;
        _this.total = response.count;
        _this.loadingList = false;
      });
    }
  }
};
exports.default = _default;